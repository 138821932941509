<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header
        :title="'Edit User'"
      />
      <common-page-loader v-if="loading" />
      <div class="trs-form-container-card">
        <users-form
          v-if="!loading"
          v-model="form"
        />
      </div>
    </template>
  </common-trs-page-container>
</template>

<script>
  import UsersForm from './Form'

  export default {
    name: 'UsersEdit',

    components: {
      UsersForm,
    },

    props: {
    },

    data: () => ({
      form: {},
      loading: false,
    }),

    computed: {
      id () {
        return this.$route.params.id
      },
    },

    created () {
      this.loadData()
    },

    methods: {
      async loadData () {},
    },
  }
</script>
